import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="The Way Things Were" />

		<h2>The Way Things Were</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST00 way things were front.jpg"
			alt="Jesse Rivest - The Way Things Were - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Words from Jesse</h3>
		<p>
			Okay, the only reason this particular "release" is on my site is to be complete with my history.
			These were original songs and I recorded them with a Tascam four-track cassette tape recorder,
			plus a couple of Shure dynamic mics (I kept those!).
			But these were my <span className="italic">first ever</span> songs and, honestly, I find <span className="italic">even the thought</span> of listening to them quite embarrassing.
			I'm leaving this page up for anyone who might remember those very old days,
			or for anyone who may actually still have a cassette or CD of these old songs kicking about.
			A special mention has to go to <span className="italic">Kim's Song</span>, which I performed at my friend Kim's funeral (she discovered she had cancer and died of it in the same year—it was quite tragic—1998 if I recall correctly).
			I also remember—again, with embarrassment—taking <span className="italic">Come Inside</span> with me to a day-long songwriting workshop hosted by Roy Forbes and Bill Henderson.
			I think Bill was trying to find nice things to say about it, while Roy (quite rightly) was exuberantly suggesting all kinds of things, like a bridge, go to the IV—or just anything to give it some life!
			Ha ha, it was embarrassing for me to hear the song played out loud in front of the group.
			Note to self: play songs out loud to a group before committing, producing, and ultimately releasing.
			Well, this first EP sort of expanded as I added songs to it;
			I've listed them here roughly in the order that I believe they originally came about.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>Come Inside</li>
			<li>Kim's Song</li>
			<li>Your Eyes</li>
			<li>Somehow</li>
			<li>Make Everything Alright</li>
			<li>3 More Months</li>
			<li>Come Inside no.2</li>
			<li>(Nullity)</li>
			<li>(Into the Night)</li>
			<li>(Rain One Night)</li>
			<li>(Unknown)</li>
			<li>(Anxiety)</li>
			<li>(Headphone Psycho)</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST00, © Jesse Rivest 1997 – 2001-ish, All Rights Reserved<br />
			UPC/EAN: n/a<br />
			Released: was never really "released" but some folks may still have a copy
		</p>

		<h3>Credits</h3>
		<p>
			Jesse Rivest wrote songs, sung them, and performed them with his guitars.<br />
			He had a Tascam Portastudio four-track cassette recorder—probably a 414. And a couple of Shure dynamic mics (57, 58).<br />
			He liked Hayden's <span className="italic">Everything I Long For</span> album,
			and Elliot Smith's <span className="italic">Miss Misery</span> from the film, <span className="italic">Good Will Hunting</span>.<br />
			Design and layout by Jesse Rivest.
		</p>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
